import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import { IRelease } from '../typings';
import './release.scss';
import moment from 'moment';
import { ExternalLink } from '../components/navigation/externalLink';
import { SoundCloudEmbed } from '../components/data/soundcloud';

interface IProps {
  data: { release: IRelease };
}

const ReleasePage: React.FunctionComponent<IProps> = (props: IProps) => {
  const { release } = props.data;
  const isReleased = moment(release.date).isBefore(moment())

  return (
    <Layout className='release-page'>
      <div className="page-split">
        <div className="page-split-left">
          <div className="release-image">
            <img src={release.imageMedium} alt={release.title} aria-hidden='true' />
          </div>
        </div>
        <div className="page-split-right">
          <h2>{release.title}</h2>
          <p>{release.artist}</p>
          <p className='release-date'><i>
            {isReleased ? 'Released ' : 'Coming '}
            {moment(release.date).format('Do MMMM YYYY')}
          </i></p><br />
          <p className='highlight'>{release.highlightLine}</p>
          <p>{release.rawMarkdownBody}</p>
          <ExternalLink url={release.soundcloudlink} site='soundcloud' />
          <ExternalLink url={release.spotifylink} site='spotify' />
          <ExternalLink url={release.bandcamplink} site='bandcamp' />
        </div>
      </div>
      {/* {!!release.reviewSnippet && <p className='highlight highlight-review'>{release.reviewSnippet}</p>} */}
      {!!release.soundcloudid && <SoundCloudEmbed trackid={release.soundcloudid} />}
    </Layout>
  )
}

export default ReleasePage;

export const pageQuery = graphql`
  query($path: String!) 
  {
    release: markdownRemark(fields: { path: { eq: $path } }) 
    {
      title
      imageMedium
      rawMarkdownBody
      date
      soundcloudlink
      spotifylink
      bandcamplink
      highlightLine
      artist
      # reviewSnippet
      # soundcloudid
    }
  }
`