import React, { CSSProperties } from 'react';

interface IExternalLink {
  site: string
  url: string
}

const externalLinkStyle: CSSProperties = {
  margin: '10px 0'
}

export const ExternalLink: React.FunctionComponent<IExternalLink> = (props: IExternalLink) =>
  props.url ?
    <a href={props.url} target='_blank' rel='noreferrer noopener'><p style={externalLinkStyle}>{props.site}</p></a>
    :
    null