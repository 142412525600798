import * as React from 'react'
import './release.scss';

interface ISoundcldoudProps {
  trackid: string
}

export const SoundCloudEmbed: React.FunctionComponent<ISoundcldoudProps> = props => {

  const { trackid } = props;

  return <iframe
    width="100%"
    height="300"
    scrolling="no"
    // frameborder="no"
    allow="autoplay"
    src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${trackid}&color=%231f160c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`}
  />
}